:root {
 --main-green-color: #2dce89;
 --main-blue-color: #172b4d;
 --logo: #063d16;
 --toggle-width: 95px;
 --toggle-ball-width: 18px;
 --danger-color: #dc3545
}

/* start global classes */

body.rtl {
 text-align: right;
}

a {
  color: unset
}

.relative {
 position: relative;
}

.blue-hover,
.danger-hover,
.success-hover,
.warning-hover,
.yellow-hover {
 transition: color 0.3s ease-in-out;
}

.blue-hover:hover {
 color: var(--blue);
}

.no-padding {
 padding: 0;
}

.rtl .rtl-remove-margin {
 margin: 0;
}

.mr-0-4 {
 margin-top: 0.4rem;
}

.success-hover:hover {
  color: var(--main-green-color);
}

.warning-hover:hover {
  color: #ffc107
}

.danger-hover:hover {
  color: var(--danger-color)
}

/* end global classes */

/* start loading bar  */

.loading-parent {
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 width: 100%;
 height: 100vh;
 display: grid;
 place-items: center;
}

/* end loading bar  */

/* start editing toggle bar in outgoing */

.custom-toggle {
 width: var(--toggle-width);
}

.custom-toggle input:checked + .custom-toggle-slider:before {
 transform: translateX(
  calc(var(--toggle-width) - (var(--toggle-ball-width) * 1.35))
 );
}

.custom-toggle-slider:after {
 padding: 0 4px;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
 padding: 0 4px;
}

/* end editing toggle bar in outgoing */

/* start type of corresponds  */

.custom-modal .custom-button-modal {
 padding: 0;
 margin: 0;
}
/* end type of corresponds  */

/* start external email */

.type-input {
 display: block;
 margin-bottom: 20px;
}

.type-icon {
 margin-right: 0.4rem;
 margin-left: 0.4rem;
}

/* end external email */

/* start outgoing */

.rtl .nav-pills .nav-item:not(:last-child) {
 padding-right: 0;
}

.rtl .nav-pills .nav-item:not(:first-child) {
 padding-right: 1rem;
}

.rtl .nav-link i.ni {
 margin-left: 0.5rem;
}

/* end outgoing */

/* start outgoing mail  */

.outgoing-header .avatar-custom {
 height: 35px;
 width: 35px;
}

.outgoing-mail p {
 color: #222;
}

.outgoing-header .media-custom {
 position: relative;
}

.outgoing-header .media-custom .header-name {
 font-weight: bold;
 color: #222;
}

.outgoing-header .content-hover {
 display: none;
 position: absolute;
 left: 0;
 bottom: 0;
 background-color: #fff;
 padding: 4px;
 width: 300px;
 -webkit-transform: translateY(115%);
 transform: translateY(115%);
 padding: 9px 7px;
 border-radius: 0px 8px 8px 8px;
 box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.rtl .outgoing-header .content-hover {
 right: 0;
}
.media-custom:hover .content-hover {
 display: flex;
}

.outgoing-header .header-buttons {
 font-size: 12px;
 align-self: flex-end;
}

.outgoing-header .content-hover img {
 height: 60px;
 width: 60px;
}

.outgoing-header .content-hover .name {
 color: #222;
 font-weight: bold;
}

.outgoing-header .content-hover:before {
 content: "";
 display: inline-block;
 position: absolute;
 top: -10px;
 left: 15px;
 border-style: solid;
 border-width: 0px 10px 10px 10px;
 border-color: transparent transparent var(--blue) transparent;
}

.rtl .outgoing-header .content-hover:before {
 right: 15px;
 left: unset;
}

.outgoing-attachment {
 text-align: center;
 overflow-y: scroll;
 scrollbar-width: thin;
 scrollbar-color: #6969dd #e0e0e0;
 scrollbar-width: thin;
 overflow-y: hidden;
 padding-top: 20px;
 padding-bottom: 20px;
}

.outgoing-attachment::-webkit-scrollbar {
 height: 7px;
}

/* Track */
.outgoing-attachment::-webkit-scrollbar-track {
 background: #f1f1f1;
}

/* Handle */
.outgoing-attachment::-webkit-scrollbar-thumb {
 background: #888;
 width: 100px;
 width: 100px;
 border-radius: 100px;
}

/* Handle on hover */
.outgoing-attachment::-webkit-scrollbar-thumb:hover {
 background: #555;
}

.outgoing-attachment a {
 margin-right: 12px;
 font-size: 14px;
}

.outgoing-attachment img {
 width: 30px;
 height: 30px;
 object-fit: contain;
 align-self: center;
}

.outgoing-carousel {
 overflow: hidden;
}

.outgoing-carousel .carosel-custom {
 width: 100%;
 height: 100%;
}

.outgoing-carousel .carosel-custom img {
 width: 100%;
 height: 400px;
 object-fit: contain;
}

.outgoing-carousel .carosel-custom .carousel-control-next-icon,
.outgoing-carousel .carosel-custom .carousel-control-prev-icon {
 filter: invert(68%) sepia(76%) saturate(5970%) hue-rotate(214deg)
  brightness(93%) contrast(92%);
}

/* end outgoing mail  */

/* start movements page  */

/* .movements .custom-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-rows: 100px;
    row-gap: 25px;
} */

.movements .custom-grid {
 position: relative;
 border-radius: 50%;
 height: 600px;
 width: 600px;
 border-radius: 50%;
 margin: 0 auto;
 border: 1px solid #ddd;
}

.movements .movements-circle {
 height: 105vh;
 overflow: hidden;
 padding-top: 30px;
 direction: ltr;
}

ul.parent-circle {
 display: block;
 position: relative;
 top: 35%;
 left: 100%;
 list-style-type: none;
 margin: 0 auto;
 padding: 0;
 transform: translate(-55%, -50%);
}

ul.parent-circle:before {
 content: "";
 position: absolute;
 width: 30rem;
 border-radius: 50%;
 height: 30rem;
 border: 1px solid hsla(171, 29%, 42%, 0.815);
 top: 0;
 left: 0;
 transform: translate(-39%, -41%);
}

li.child-circle {
 position: absolute;
 box-sizing: content-box;
 width: 70px;
 height: 70px;
 padding: 7px;
 display: flex;
 background-color: red;
 border-radius: 50%;
 justify-content: center;
 align-items: center;
 font-size: 12px;
 text-align: center;
 transition: all 1s linear;
 -webkit-transition: all 1s linear;
 -moz-transition: all 1s linear;
}

/* start table  */

.table-row:hover {
 background-color: hsl(0, 1%, 93%);
 cursor: pointer;
 transition: background 0.2s ease-in-out;
}

/* end table  */

/* start modal  */

.rtl .modal-footer .btn + .btn.modal-custom-button {
 margin-left: 0;
}
/* end modal  */

/* start dropzone */
.dropzone-custom p {
  height: 100px;
  border: 1px
  dashed #ccc;
  padding: 10px;
  transition: filter .3s ease-in-out;
}

.dropzone-custom p:hover {
  filter: brightness(0.8);
  cursor: pointer;
}

.dropzone-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.dropzone-files .dropzone-content {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.dropzone-files img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.dropzone-files .dropzone-name {
  margin-right: 18px;
  margin-left: 18px
}

.dropzone-files button {
  border: 1px solid var(--danger-color);
  background: transparent;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--danger);
  transition: all .3s ease-in-out;
}

.dropzone-files button:hover {
  color: #fff;
  background-color: var(--danger-color);
}

.custom-dropzone-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dropzone-content > span {
  background-color: #e9e3e3;
  padding: 5px 14px;
}

/* end dropzone */

/* start editor  */

li[data-mce-style="text-align: right;"] {
  direction: rtl;
}

/* end editor  */

/* start marginalization  */

.custom-top-modal {
  padding-left: 1.55rem;
  padding-right: 1.55rem;
}

.custom-modal-wrapper {
  overflow: hidden;
  text-align: right;
}

.rtl .custom-modal-wrapper {
  text-align: left;
}

.custom-modal-wrapper img {
  height: 200px;
  width: 200px;
  object-fit: contain;
}
/*  marginalization  */

.langbtn[data-toggle="buttons"]:not(.btn-group-colors) > .btn {
  font-size: x-small;
}

.cursor-pointer {
  cursor: pointer;
}

.main-content .navbar-top {
  padding-top: 40px;
}

/* start handle attachment  */

.attachment {
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  overflow-y: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
}

.attachment::-webkit-scrollbar {
  height: 7px;
}

/* Track */
.attachment::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.attachment::-webkit-scrollbar-thumb {
  background: #888;
  width: 100px;
  width: 100px;
  border-radius: 100px;
}

/* Handle on hover */
.attachment::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.attachment a {
  margin-right: 12px;
  font-size: 14px;
}

.attachment i {
  object-fit: contain;
  align-self: center;
  font-size: 45px;
}
/* end attachemnts  */